import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ValueProp from "../components/ValueProp";
import ProductBenefits from "../components/ProductBenefits";
import ProductFeatures from "../components/ProductFeatures";
import Testimonials from "../components/Testimonials";
import CallToAction from "../components/CallToAction";

export default function Home() {
  return (
    <Layout>
      <SEO title="Home" />
      <ValueProp />
      <ProductBenefits />
      <ProductFeatures />
      <Testimonials />
      <CallToAction />
    </Layout>
  );
}
